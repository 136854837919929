import "./App.css";
import Home from "./pages/Home";
import ReactGA from "react-ga4";
ReactGA.initialize("G-8NYZY4JDDZ");

function App() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
